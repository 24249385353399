import { useEffect, useRef, useState } from "react"
import { SVGLoader } from "three-stdlib";
import * as THREE from 'three';
import { MeshLine, MeshLineGeometry, MeshLineMaterial } from '@lume/three-meshline'

export default function (props) {
    const groupContainerRef = useRef();
    const [meshLine, setMeshline] = useState();

    // Load path from an svg
    useEffect(function () {
        if (props.src && groupContainerRef.current) {
            loadSvgPath();
            return;
        }

        if (!props.src && props.customPathPoints && groupContainerRef.current) {
            groupContainerRef.current.add(createMeshLine(props.customPathPoints, props.lineDivisions));
        }

    }, [props.src, props.customPath, groupContainerRef, props.strokeColor]);

    function loadSvgPath() {
        // SVG Conversion is based on https://threejs.org/examples/?q=svg#webgl_loader_svg
        const loader = new SVGLoader();
        loader.load(props.src, function (data) {
            const groupContainer = groupContainerRef.current;

            for (const path of data.paths) {
                for (const subPath of path.subPaths) {
                    const subPathPoints = subPath.getPoints();

                    // convert points to 3D
                    let points = [];

                    // add subpaths points
                    const svgScalar = props.svgScalar ? props.svgScalar : 0.01;
                    const positionOffset = props.positionOffset ? props.positionOffset : new THREE.Vector3(0, 0, 0);
                    for (const subPathPoint of subPathPoints) {
                        let point = new THREE.Vector3(
                            subPathPoint.x * svgScalar + positionOffset.x,
                            -1 * subPathPoint.y * svgScalar + positionOffset.y,
                            0 + positionOffset.z
                        );
                        points.push(point);
                    }

                    // console.log(`SB: drew line from svg ${props.src} with first and last points`, points[0], points[points.length - 1]);

                    groupContainer.add(createMeshLine(points, points.length * 4));
                }
            }
        });
    }

    function createMeshLine(points, divisions) {
        if (props.invertDrawDirection) {
            points = points.reverse();
        }

        const strokeColor = props.strokeColor;
        const material = new MeshLineMaterial({
            color: strokeColor,
            opacity: 1,
            transparent: true,
            dashArray: 2,
            lineWidth: 0.1
        });

        const geometry = new MeshLineGeometry();
        const splineCurve = new THREE.CatmullRomCurve3(points);

        geometry.setPoints(splineCurve.getPoints(divisions));
        const meshLine = new MeshLine(geometry, material);

        if (props.setState) {
            props.setState({ meshLine, splineCurve })
        }

        return meshLine;
    }

    return <>
        <group position={props.position} scale={props.scale} ref={groupContainerRef}>
        </group>
    </>
}
import logo from './logo.svg';
import './App.css';
import App3D from './Components/App3D';
import { Canvas } from '@react-three/fiber';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a href="/">
          <img className="sb-header__site-logo" src="/assets/dr-sandra-logo.png"></img>
        </a>

      </header>
      <div>
        <div className="sb-app3d__wrapper" >
          <Canvas>
            <App3D></App3D>
          </Canvas>
        </div>
      </div>

    </div>
  );
}

export default App;

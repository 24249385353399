import { createRoot } from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import { Box, OrbitControls, PerspectiveCamera } from '@react-three/drei'
import SBLineDrawer from './SBLineDrawer'
import * as THREE from 'three';
import { useLayoutEffect, useRef, useState } from 'react';
import gsap, { Bounce, Power0, Power1 } from 'gsap';
import { useControls } from 'leva'

export default function App3D() {
    const cameraRef = useRef();
    const [proposalState, setProposalState] = useState();
    const [heartState, setHeartState] = useState();
    const [linkState, setLinkState] = useState();


    const { drawingSpeed, strokeColor } = useControls({
        drawingSpeed: {
            value: 20,
            min: 0,
            max: 100,
            step: 1
        },
        strokeColor: "#000000"
    })

    useLayoutEffect(() => {
        let tl;
        if (!proposalState || !heartState | !linkState || !cameraRef.current) {
            return;
        }

        // create our context. This function is invoked immediately and all GSAP animations and ScrollTriggers created during the execution of this function get recorded so we can revert() them later (cleanup)
        let ctx = gsap.context(() => {
            const meshLine = proposalState.meshLine;
            const linkMeshLine = linkState.meshLine;
            const heartMeshline = heartState.meshLine;

            const proposalDuration = 1 / drawingSpeed * proposalState.splineCurve.getLength();
            const linkDuration = 1 / drawingSpeed * linkState.splineCurve.getLength();
            const heartDuration = 1 / drawingSpeed * heartState.splineCurve.getLength()

            tl = gsap.timeline();
            tl.to(cameraRef.current.position, { x: 0, y: -6, z: 20, duration: proposalDuration + linkDuration + heartDuration }, "<");
            tl.to(cameraRef.current.rotation, { x: 0, y: -.2 * Math.PI, z: 0, duration: proposalDuration + linkDuration + heartDuration + 100 * 1 / drawingSpeed }, "<");
            tl.fromTo(meshLine.material, { dashOffset: 0 }, { dashOffset: 1, duration: proposalDuration, ease: Power0.easeNone }, "<");
            tl.fromTo(linkMeshLine.material, { dashOffset: 0 }, { dashOffset: 1, duration: linkDuration, ease: Power0.easeNone }, "<+" + proposalDuration);
            tl.fromTo(heartMeshline.material, { dashOffset: 0 }, { dashOffset: 1, duration: heartDuration, ease: Power0.easeNone }, "<+" + linkDuration);
        }); // <- IMPORTANT! Scopes selector text

        return () => {
            tl.kill();
            ctx.revert()
        }; // cleanup

    }, [proposalState, heartState, linkState, drawingSpeed, strokeColor]);
    return (
        <>
            {/* <axesHelper></axesHelper> */}
            < PerspectiveCamera ref={cameraRef} position={[15, -5, 30]} makeDefault ></PerspectiveCamera >
            {/* <OrbitControls target={new THREE.Vector3(0, 6, 0)}></OrbitControls> */}
            <SBLineDrawer strokeColor={strokeColor} setState={setProposalState} invertDrawDirection position={[0, 0, 0]} src="/assets/paths/sample-proposal-path.svg"></SBLineDrawer>
            <SBLineDrawer strokeColor={strokeColor} setState={setHeartState} invertDrawDirection svgScalar={0.03} positionOffset={new THREE.Vector3(-10, 15, -40)} src="/assets/paths/sample-heart-path.svg"></SBLineDrawer>
            <SBLineDrawer strokeColor={strokeColor} setState={setLinkState} invertDrawDirection customPathPoints={[
                new THREE.Vector3(23.4726, -13.1735, 0),
                new THREE.Vector3(28.4726, -13.1735, 0),
                new THREE.Vector3(25.4726, -10.1735, -5),
                new THREE.Vector3(30.4726, -13.1735, -10),
                new THREE.Vector3(40.4726, -13.1735, -25),
                new THREE.Vector3(60.8561, -13.7838, -40),
                new THREE.Vector3(48.8561, -13.7838, -40)
            ]} lineDivisions={[100]} ></SBLineDrawer>
        </>
    )
}